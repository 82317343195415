const ar = {
  restaurantApprovalMessage: "تهانينا! تمت الموافقة على مطعمك",
  restaurantApprovalDescription: "",

  restaurantWaitingMessage: "",
  restaurantWaitingDescription: "",

  "Today's Pickup Orders": "طلبيات الاستلام الشخصي اليوم",
  // sidenav links
  dashboard: "اللوحة الرئيسية",
  orderManager: "إدارة الطلبيات",
  orderHistory: "سجل الطلبيات",
  driverManager: "الكباتن",
  menuManager: "إدارة قائمة الطعام",
  discountManager: "إدارة العروض",
  reviewManager: "إدارة التقييمات",
  revenueManager: "إدارة الايرادات",
  invoicesManager: "دفع الفواتير",
  notifications: "الاشعارات",
  settings: "الاعدادات",
  "Approve All":" موافقة على جميع الطلبيات",

  // header دفع الكتروني
  online: "متوفر",
  online_: "دفع الكتروني",
  Online_: "دفع الكتروني",
  Online: "متوفر",
  offline: "مغلق",
  Offline: "مغلق",
  profile: "الملف الشخصي",

  editProfile: "تعديل الملف الشخصي",
  changePassword: "تغيير كلمة المرور",
  logout: "الخروج",
  Logout: "تسجيل خروج",
  viewAll: "عرض الكل",
  "Are you sure you want to Logout the Application?":
    "هل أنت متأكد أنك تريد تسجيل الخروج من التطبيق؟",

  // master
  Available: "متاح",
  customer: "العميل",
  date: "التاريخ",
  time: "الوقت",
  and: "و",
  list: "قائمة",
  search: "بحث",
  year: "السنة",
  month: "الشهر",
  reset: "إعادة التعيين",
  "Export To Excel": "تصدير الى اكسل",
  add: "إضافة",
  Add: "إضافة",
  create: "إنشاء",
  Edit: "تعديل",
  delete: "حذف",
  Status: "الحالة",
  ok: "حفظ",
  OK: "حفظ",
  Image: "صورة",
  Action: "إجراء",
  okay: "إغلاق",
  leave: "مغادرة",
  cancel: "إغلاق",
  quantity: "الكمية",
  amount: "المبلغ",
  withExcel: "عبر اكسل",
  manually: "يدويا",
  "Phone Number": "رقم الهاتف",
  "S. NO": "الرقم",
  "Created On": "إنشاء بتاريخ",
  Select: "إختيار",
  "Start Date": "تاريخ البداية",
  "End Date": "تاريخ النهاية",
  Active: "فعال",
  "De-Active": "غير فعال",
  Submit: "أرسل",
  "non-vegetarian": "غير نباتي",
  vegetarian: "نباتي",
  halal: "حلال",

  monthly: "شهري",
  weekly: "اسبوعي",
  quarterly: "ربع سنوي",

  // order
  orders: "الطليات",
  order: "الطلب",
  "Order ID": "رقم الطلب",
  "Order Time": "وقت الطلب",
  "Platform Commission": "عمولة المنصة",
  "Payment Mod": "طريقة الدفع",
  "Orders In Progress": "الطلبات قيد التقدم",
  "Today's Orders": "طلبيات اليوم",
  "Order List": "قائمة الطلبيات",
  "Company Order List": "قائمة طلبات الشركة",
  "Out For Delivery": "خارج للتوصيل",
  "out for delivery": "خارج للتوصيل",
  "Cancel Order": "إلغاء الطلب",
  "Orders Our For Delivery": "طلبيات خارج للتوصيل",

  //order status
  Accepted: "موافقة",
  Processing: "قيد التحضير",
  processing: "قيد التحضير",
  "Ready To Pickup": "جاهزة للاستلام",
  "Ready To Pick Up": "جاهزة للاستلام",
  "ready to pickup": "جاهزة للاستلام",
  Cancelled: "ملغاة",
  Pickup: "استلام شخصي",
  "Order status": "حالة الطلب",
  "Delivery Agent Name": "اسم الكابتن",
  "Arrived At Customer": "وصل إلى العميل",
  "Driver Arrived At Customer": "وصل الكابتن إلى العميل",
  Delivered: "تم التوصيل",
  delivered: "تم التوصيل",
  Rejected: "رفضت",
  "Are you sure you want to cancel this order?":
    "هل أنت متأكد أنك تريد إلغاء هذا الطلب؟",
  "Payment Issues": "مشكلة في الدفع",
  "Unavailability of Items": "عدم توفر العناصر",
  "Delivery Address Inaccuracy": "عدم دقة عنوان التسليم",
  "Security or Fraud Concerns": "مخاوف أمنية أو الاحتيال",
  "Violation of Platform Policies": "انتهاك سياسات المنصة",
  "Customer Not Accept": "عدم قبول العميل للطلب",
  "Customer Absent": "عدم توفر العميل",

  inProgress: "قيد التقدم",
  outForDelivery: "خارج للتوصيل",
  cancelOrderConfirmation: "متأكد من الغاء الطلب؟",

  deliveryAgent: "كابتن",

  Sales: "المبيعات",
  numberOfUsers: "عدد العملاء",

  revenue: "الدخل",

  // menu manager
  "e.g Italian, Mexican, Thai, Chinese, Punjabi":
    "مثال: برغر، بيتزا، فطور، حلويات، قهوة",
  uploadMenuDescription:
    "هل تريد تحميل القائمة الخاصة بك باستخدام اكسل أم إدخالها يدويًا؟",
  "Upload Menu": "تحميل القائمة",
  "Are you sure you want to Delete this category?":
    "هل أنت متأكد أنك تريد حذف هذه الفئة؟",
  "Food Category": "فئة الطعام",
  "Category Image": "صورة الفئة",
  Choices: "الخيارات",
  "Add-ons": "الاضافات",
  Items: "العناصر",

  "Add New Category": "إضافة فئة جديدة",
  "Category Name": "اسم التصنيف",
  "Category Name Arabic": "اسم الفئة عربي",
  "All Category": "جميع الفئات",
  "Enter Category Name": "ادخل اسم الفئة",
  "Edit Category": "تعديل الفئة",
  "Delete Category": "حذف الفئة",

  "Item Sizes": "أحجام العنصر",
  "Item Sizes Arabic": "أحجام العنصر بالعربية",
  "Add New Item Size": "إضافة حجم عنصر جديد",
  "Are you sure you want to Delete this item size?":
    "هل أنت متأكد أنك تريد حذف هذا الحجم؟",
  "Delete Item Size": "حذف حجم العنصر",

  "Choice Name": "اسم الخيار",
  "Choice Name Arabic": "اسم الخيار بالعربية",
  "Add New Choice": "إضافة خيار جديد",
  "Are you sure you want to Delete this choice?":
    "هل أنت متأكد أنك تريد حذف هذا الخيار؟",
  "Delete Choice": "حذف الخيار",

  "Item Name": "اسم العنصر",
  "Delete Food Item": "حذف العنصر",
  "Are you sure you want to Delete this food item?":
    "هل أنت متأكد أنك تريد حذف هذا العنصر؟",

  menu: "قائمة",
  food: "الطعام",
  items: "العناصر",

  Price: "السعر",
  paymentMod: "طريقة الدفع",
  "Payment Method": "طريقة الدفع",
  platformCommission: "نسبة المنصة",
  platformType: "نوع المنصة",

  //discount management
  "Discount Management": "إدارة العروض",
  discount: "العرض",
  "Discount Name": "اسم الخصم",
  "Discount Name Arabic": "اسم الخصم عربي",
  Discounts: "العروض",
  "discount revenue": "دخل العرض",
  "Create Discount": "إنشاء عرض",
  "Delete Discount": "حذف عرض",
  "Are you sure you want to Delete this discount?":
    "هل أنت متأكد أنك تريد حذف هذا العرض؟?",
  "Banner Image": "صورة الاعلان",

  //driver
  driver: "الكابتن",
  "Driver ID": "هوية الكابتن",
  "Driver Name": "اسم الكابتن",
  "Driver Profile": "ملف الكابتن",
  assignedDrivers: "تعيين كباتن",
  id: "الرقم",
  waitForApproval: "بانتظار الموافقة",

  // category management
  "Category Management": "إدارة الفئات",

  "Bank Account / Wallet": "حساب البنك  المحفظة",
  General: "عام",
  "Select Language": "اختيار اللغة",
  "Please select the language!": "يرجى اختيار اللغة!",
  "Select Country": "اختيار الدولة",
  English: "English",
  Arabic: "عربي",
  "**Images should be 600x400 for best view in gallery image. You can select only (.gif, .png, .jpeg, .jpg) format files upto 1 MB file size..!!!":
    "**يجب أن تكون الصور بحجم 600 × 400 للحصول على أفضل عرض في صورة المعرض. يمكنك تحديد ملفات بتنسيق (.gif و.png و.jpeg و.jpg) فقط يصل حجمها إلى 1 ميغابايت.",
  "Image is required": "الصورة مطلوبة",
  "Name should contain at least 2 characters!":
    "يجب أن يحتوي الاسم على حرفين على الأقل!",
  "Name should not contain more then 20 characters!":
    "يجب ألا يحتوي الاسم على أكثر من 20 حرفًا!",
  "Arabic Name is required": "الاسم العربي مطلوب",
  "Name is required": "الاسم مطلوب",
  "Ingredients Category": "فئة المكونات",
  "Item Ingredients": "مكوّن",
  "Please Enter Price": "يرجى ادخال السعر",
  "Select Ingredients Size": "اختار حجم المكوّن",
  "Ingredients Size": "حجم المكوّن",
  "Add Price": "إضافة السعر",
  "Please Select Size": "يرجى اختيار الحجم",
  "Select Size": "اختيار الحجم",
  "Contain Size": "يحتوي أحجام",
  "Item Description Arabic": "وصف السلعة عربي",
  "Item Description is required": "وصف العنصر مطلوب",
  "Description should not contain more then 200 characters!":
    "يجب ألا يحتوي الوصف على أكثر من 200 حرف!",
  "Description should contain at least 2 characters!":
    "يجب أن يحتوي الوصف على حرفين على الأقل!",
  "Item Description": "وصف السلعة",
  "Upload Image": "تحميل صورة",
  "Item Name Arabic": "اسم العنصر عربي",
  "Item Name is required": "اسم العنصر مطلوب",
  "Please Select Category": "يرجى اختيار الفئة",
  "Select Category": "اختيار الفئة",
  "Edit Item": "تعديل العنصر",
  "Add New Item": "إضافة عنصر جديد",
  Delete: "حذف",
  Add: "يضيف",
  Country: "يمسح",
  //added by shivraj--------------------

  "Schedule Order List": "قائمة طلبات الجدولة",
  Review: "تقييمات",
  Featured: "مميز",
  "Order List": "قائمة الطلبات",
  "Pending Order List": "قائمة الطلبات المعلقة",
  "Export To Excel": "تصدير إلى إكسل",
  "Order ID": "رقم الطلب",
  "Restaurant Order id": "رقم الطلب - المطعم",
  customer: "العميل",
  items: "العناصر",
  "Payment Method": "طريقة الدفع",
  date: "التاريخ",
  Price: "السعر",
  "Platform Commission": "عمولة المنصة",
  "Delivery status": "حالة التوصيل",
  View: "العرض",
  Status: "الحالة",
  "Order Details": "تفاصيل الطلب",
  "Customer Details": "تفاصيل العميل",
  Name: "الاسم",
  Phone: "رقم الهاتف",
  Email: "البريد الالكتروني",
  "Address Details": "تفاصيل العنوان",
  Address: "العنوان",
  Area: "المنطقة",
  City: "المدينة",
  "Order Total": "قيمة الطلب",
  Tax: "الضريبة",
  "Food Items Total": "إجمالي عناصر الطعام",
  Tip: "البقشيش",
  "total (for restaurant)": "القيمة الكلية (للمطعم)",
  Okay: "موافق",
  "Schedule time": "وقت الجدولة",
  "Upload Excel": "رفع إكسل",
  "Import Excel": "استيراد إكسل",
  "Download Sample File": "تحميل ملف عينة",
  "Download Sample": "تحميل عينة",
  File: "ملف",
  OR: "أو",
  "Click or Drag File to This Area to Upload":
    "انقر أو اسحب الملف إلى هذا المكان للرفع",
  "Please Select an Excel File.": "يرجى تحديد ملف إكسل.",
  Import: "استيراد",
  "Category Sort Order": "ترتيب فرز الفئات",
  "S. No": "رقم تسلسلي",
  "Enter Category Sort Order": "أدخل ترتيب فئة الفئة",
  "Category Sort Order is required": "مطلوب ترتيب فئة الفئة",
  "**Images should be 720x480 for best view in gallery image. You can select only (.gif, .png, .jpeg, .jpg) format files upto 1 MB file size..!!!":
    "**يجب أن تكون الصور بحجم 720x480 للعرض الأفضل في صور المعرض. يمكنك اختيار ملفات بصيغة (.gif, .png, .jpeg, .jpg) بحجم يصل إلى 1 ميغابايت فقط..!!!",
  Add: "إضافة",
  Update: "تعديل",
  "This category contains food items, please delete food item before deleting category":
    "هذه الفئة تحتوي على عناصر، يرجى حذف العناصر قبل حذف الفئة",
  "Are you sure you want to Delete this category?":
    "هل أنت متأكد أنك تريد حذف هذه الفئة؟",
  "This category contains food items, please inactive food item before deactivating category":
    "تحتوي هذه الفئة على عناصر طعام، يرجى إلغاء تنشيط عنصر الطعام قبل إلغاء تنشيط الفئة",
  "(Larger, Medium, Small, Half, Full)": "(كبير، متوسط، صغير، نصف، كامل)",
  "Delete Item Size": "حذف حجم العنصر",
  "Are you sure you want to Delete this item size?":
    "هل أنت متأكد أنك تريد حذف حجم العنصر؟",
  "This item size contains food items, please delete food item before deleting item size":
    "يحتوي حجم العنصر هذا على عناصر غذائية، يرجى حذف العنصر الغذائي قبل حذف حجم العنصر",
  Submit: "إرسال",
  "Add New": "اضف جديد",
  " Item Size": " حجم العنصر",
  "Size Name": "اسم الحجم",
  "Name is required": "الاسم مطلوب",
  "Name should not contain more then 20 characters!":
    "يجب ألا يحتوي الاسم على أكثر من 20 حرف!",
  "Name should contain at least 2 characters!":
    "يجب أن يحتوي الاسم على ما لا يقل عن حرفين!",
  "Enter Size Name": "أدخل اسم الحجم",
  "Size Name Arabic": "اسم الحجم عربي",
  "Arabic Name is required": "اسم العربي مطلوب",
  "Name should not contain more then 20 characters!":
    "يجب ألا يحتوي الاسم على أكثر من 20 حرفًا!",
  "Name should contain at least 2 characters!":
    "يجب أن يحتوي الاسم على ما لا يقل عن حرفين!",
  "(Mild, Medium, Hot) [This will not have a price.]":
    "(خفيف، متوسط، حار) [لن يكون لها سعر]",
  "This choice contains food items, please delete food item before deleting choice":
    "هذا الخيار يحتوي على عناصر، يرجى حذف العناصر قبل حذف الخيار",
  "This choice contains food items, please inactive food item before inactive choice":
    "هذا الاختيار يحتوي على عناصر طعام، يرجى إلغاء تنشيط عنصر الطعام قبل إلغاء تنشيط الاختيار",
  "Inactive Choice": "اختيار غير نشط",
  "Choice Name": "اسم الاختيار",
  " Choice": " اختيار",
  "Name should contain at least 2 characters!":
    "يجب أن يحتوي الاسم على ما لا يقل عن حرفين!",
  "Enter Choice Name": "أدخل اسم الاختيار",
  "Choice Name Arabic`": "اسم الاختيار بالعربية",
  "Add Choice Option": "إضافة خيار للاختيار",
  "Please enter choice option": "يرجى إدخال خيار للاختيار",
  "Add Choice Option Arabic": "إضافة خيار للاختيار بالعربية",
  "Please enter choice option": "يرجى إدخال خيار للاختيار",
  "Ingredient Category Name": "اسم فئة المكون",
  "Ingredient Category": "اسم فئة المكون",

  Save: "حفظ",
  "Add New Ingredient Category": "إضافة فئة مكون جديد",
  "(Toppings, Extras etc.)": "(مكملات، إضافات إلخ.)",
  "Ingredients Category": "فئة المكونات",
  "Add New Ingredients Category": "إضافة فئة جديدة للمكونات",
  "This category contains food items, please inactive food item before deactivating category":
    "هذه الفئة تحتوي على عناصر طعام، يرجى إلغاء تنشيط عنصر الطعام قبل إلغاء تنشيط الفئة",
  "Inactive Category": "فئة غير نشطة",
  "This category contains food items, please delete food item before deleting category":
    "هذه الفئة تحتوي على عناصر طعام، يرجى حذف عنصر الطعام قبل حذف الفئة",
  "Delete Category": "حذف الفئة",
  "Are you sure you want to Delete this category?":
    "هل أنت متأكد أنك تريد حذف هذه الفئة؟",
  "Name Arabic": "الاسم بالعربية",
  "Enter Name": "أدخل الاسم",
  Name: "الاسم",
  "Ingredients Category arabic name": "اسم الفئة بالعربية",
  " Add Ingredients": " إضافة مكونات",
  "Are you sure you want to Delete this Ingredient?":
    "هل أنت متأكد أنك تريد حذف هذا المكون؟",
  "Delete Ingredient": "حذف المكون",
  "This ingredient contains food items, please delete food item before deleting ingredient":
    "هذا المكون يحتوي على عناصر طعام، يرجى حذف عنصر الطعام قبل حذف المكون",
  "Inactive Ingredient": "مكون غير نشط",
  "This ingredient contains food items, please inactive food item before inactive ingredient":
    "هذا المكون يحتوي على عناصر طعام، يرجى إلغاء تنشيط عنصر الطعام قبل إلغاء تنشيط المكون",
  Ingredient: "المكونات",
  "Ingredients Category": "فئة المكونات",
  "Select Ingredients Category": "اختر فئة المكونات",
  "Toppings Name": "اسم الاضافة",
  "Toppings Name Arabic": "اسم المكملات بالعربية",
  "Contain Size": "يحتوي على حجم",
  "Ingredients Size": "حجم المكونات",
  "Select Ingredients Size": "اختر حجم المكونات",
  "Please Enter Price": "يرجى إدخال السعر",
  "Please Enter Item Price": "يرجى إدخال سعر العنصر",
  "Item Arabic Name": "اسم العنصر باللغة العربية",
  "This food contains discounts, please delete discounts before deleting food item":
    "هذا الطعام يحتوي على خصومات، يرجى حذف الخصومات قبل حذف العنصر",
  Category: "الفئة",
  "**Images should be 720x480 for best view in gallery image. You can select only (.gif, .png, .jpeg, .jpg) format files upto 1 MB file size..!!!":
    "**يجب أن تكون الصور بحجم 720x480 للعرض الأفضل في صور المعرض. يمكنك اختيار ملفات بصيغة (.gif, .png, .jpeg, .jpg) بحجم يصل إلى 1 ميغابايت فقط..!!!",
  "Price is required": "السعر مطلوب",
  Type: "النوع",
  "please select a type": "يرجى اختيار نوع",
  Vegetarian: "نباتي",
  "Non-Vegetarian": "غير نباتي",
  "Non Veg types": "أنواع غير نباتية",
  "NON-HALAL": "غير حلال",
  HALAL: "حلال",
  "Add More Customization": "إضافة المزيد من التخصيص",
  "Do This Item Contains Choice?": "هل يحتوي هذا العنصر على خيار؟",
  "Multi Select Option": "عدد التحديدات المتاحة",
  "Multiple Select Option": "خيار التحديد المتعدد",
  "Is Mandatory": "مطلوب",
  "Do This Item Contains Add Ons?": "هل يحتوي هذا العنصر على إضافات؟",
  "Include this Category": "تضمين هذه الفئة",
  "Type Of Selection": "نوع الاختيار",
  Single: "خيار واحد",
  Multi: "متعدد الخيارات",
  "Min Size": "الحد الادنى",
  "Enter Max Size": "الحد الاقصى",
  "Select All": "اختيار الجميع",
  Amount: "المبلغ",
  "Used Count": "عدد الاستخدامات",
  "Upload Banner Image": "تحميل صورة البانر",
  "Banner image is required": "صورة الشعار مطلوبة",
  "**Images should be 720x480 for best view in gallery image. You can select only (.gif, .png, .jpeg, .jpg) format files upto 1 MB file size..!!!":
    "**يجب أن تكون الصور بحجم 720x480 للعرض الأفضل في صور المعرض. يمكنك اختيار ملفات بصيغة (.gif, .png, .jpeg, .jpg) بحجم يصل إلى 1 ميغابايت فقط..!!!",
  "Discount Name": "اسم الخصم",
  "Enter Discount Name": "أدخل اسم الخصم",
  "Name should contain more then 250 characters!":
    "يجب أن يحتوي الاسم على أكثر من 250 حرفًا!",
  "Please Enter Restaurant Name": "يرجى إدخال اسم المطعم",
  "Discount Name Arabic": "اسم الخصم بالعربية",
  Type: "نوع العرض",
  Percentage: "نسبة خصم",
  Flat: "خصم قيمة محددة",
  "Change Password": "تغيير كلمة المرور",
  "New Password": "كلمة المرور الجديدة",
  "Old Password": "كلمة المرور القديمة",
  "Confirm New Password": "تأكيد كلمة المرور الجديدة",
  "Discount Percentage": "قيمة نسبة الخصم",
  "Please Enter Discount Percentage": "يرجى إدخال نسبة الخصم",
  "Fix Discount Amount": "قيمة الخصم المحددة",
  "Please Enter Discount Amount": "يرجى إدخال مبلغ الخصم",
  "Apply on All Items": "تطبيق على كل عناصر قائمة الطعام",
  "Select the Category": "اختيار الفئة",
  "Missing Category Selection": "اختيار الفئة مفقود",
  "Select Food Category": "اختر فئة الطعام",
  "Select the Product": "اختيار العناصر",
  "Coupon Code": "رمز الكوبون",
  "Name should contain more then 7 characters!":
    "يجب أن يحتوي الاسم على أكثر من 7 أحرف!",
  "Please Enter Coupon Code": "يرجى إدخال كود الكوبون",
  "Enter Coupon Code": "أدخل كود الكوبون",
  "Discount Time": "صلاحية العرض",
  "Minimum Subtotal Amount (of selected items)":
    "الحد الأدنى لإجمالي الطلب (من العناصر المحددة)",
  "Please select the time": "يرجى اختيار الوقت",
  "Minimum Subtotal Amount": "الحد الأدنى لإجمالي المبلغ",
  "Please Enter Min Subtotal Amount": "يرجى إدخال الحد الأدنى لإجمالي المبلغ",
  "Enter Minimum Subtotal Amount": "أدخل الحد الأدنى لإجمالي المبلغ",
  "If the Subtotal amount not reached, then the message will appear to the customer":
    "إذا لم يتم الوصول إلى الحد الادنى لإجمالي الطلب فستظهر الرسالة للعميل",
  "Please Enter the message": "يرجى إدخال الرسالة",
  "Enter the message": "أدخل الرسالة",
  "Max Discount": "الحد الاقصى للخصم",
  "Please Enter Max Amount": "يرجى إدخال الحد الأقصى للمبلغ",
  "Enter Max Discount Amount": "أدخل الحد الأقصى للخصم",
  "Select User Type": "حدد نوع العميل",
  "Please select user type": "يرجى اختيار نوع المستخدم",
  "Uses Per User": "عدد الاستخدامات لكل عميل",
  "Please Enter Uses Per User": "يرجى إدخال الاستخدامات لكل مستخدم",
  "Enter Uses Per User": "أدخل الاستخدامات لكل مستخدم",
  "Max Number Of Uses In Total": "الحد الأقصى لعدد استخدامات الكود في المجموع",
  "Please Enter Max Number Of Uses In Total":
    "يرجى إدخال أقصى عدد للمستخدمات الإجمالي",
  "Enter Max Number Of Uses In Total": "أدخل أقصى عدد للمستخدمات الإجمالي",
  "Custom Message": "رسالة مخصصة للعميل بعد استخدام الخصم",
  "Please Enter the message": "يرجى إدخال الرسالة",
  "Enter the message": "أدخل الرسالة",
  "Custom Message Arabic": "رسالة مخصصة للعميل بعد استخدام الخصم",
  "Final Step": "الخطوة الاخيرة",
  "Unlimited times for all users": "عدد غير محدودة لجميع المستخدمين",
  "Once for new user for first order":
    "مرة واحدة للعميل الجديد وللطلب الأول فقط",
  "Once per user": "مرة واحدة لكل عميل",
  "Define custom limit per user": "تحديد الحد المخصص لكل عميل",
  Next: "التالي",

  "A Final Step!! You are about to Add Discount":
    "خطوة أخيرة!! أنت على وشك إضافة عرض",
  Reviews: "التقييمات",
  View: "العرض",
  Reply: " الرد على التقييم",
  Comment: "الملاحظة",
  "Your Comment": "ملاحظتك",
  "Type your comment here": "اكتب ملاحظتك هنا",

  "Total Sales": "إجمالي المبيعات",
  "Today's Sales": "مبيعات اليوم",
  "total Online Sales": "إجمالي المبيعات عبر الدفع الالكتروني",
  "total COD Sales": "إجمالي المبيعات النقدية",
  "total Revenue": "إجمالي الإيرادات",
  Online: "الدفع الالكتروني",
  "Total COD": "إجمالي النقدي",
  "Platform Commission": "عمولة المنصة",
  "1st Week": "الاسبوع الاول",
  "2nd Week": "الاسبوع الثاني",
  "3rd Week": "الاسبوع الثالث",
  "4th Week": "الاسبوع الرابع",
  "Orders Delivered": "الطلبات المكتملة",
  "Orders Canceled": "الطلبات الملغاة",

  COD: " النقدي",
  "Pay to Admin": "الدفع للمنصة",
  "Receive from Admin": "الاستلام من المشرف",
  "Restaurant Revenue": "إيرادات المتجر",
  "Admin Commision": "عمولة المنصة",
  "Received Amount": "المبلغ المستلم",
  "Total Orders": "مجموع الطلبات",
  "COD Revenue": "الايرادات النقدية",
  "Online Revenue": "إيرادات عبر الإنترنت",
  "Today's Notifications": "إشعارات اليوم",
  "Yesterday's Notifications": "إشعارات الأمس",
  Wallet: "المحفظة",
  "My Wallet": "محفظة المتجر",
  Balance: "الرصيد",
  " Add money": "اضافة رصيد",
  "Add Bank Account": "إضافة حساب بنكي",

  "select month to view payments": "اختر الشهر لعرض الدفعات",
  Month: "الشهر",
  Cash: "النقدي",
  "Online Payments": "الدفع الالكتروني",
  "Last 30 days": "الـ30 يومًا الأخيرة",
  Name: "الاسم",
  "Arrived At Restaurant": "وصل إلى المطعم",
  "Order Picked Up": "تم استلام الطلب",

  Bank: "البنك",
  "Bank Account": "الحساب البنكي",
  "Driver Assigned": "تم تعيين الكابتن",
  Iban: "الآيبان",
  Account: "الحساب",
  Beneficiary: "المستفيد",
  " Change Language": "تغيير اللغة",
  "Choose Language": "اختر اللغة",
  "Please select the language!": "يرجى اختيار اللغة!",
  "Select Language": "اختر اللغة",
  "Support Center": "خدمة الشركاء",
  Version: "الاصدار",

  "Contact Us": "تواصل معنا عبر الواتساب",
  "Email Us": "تواصل معنا بالبريد الالكتروني",
  "Version 2.8.1": "الإصدار 2.8.1",
  "Edit Bank Account": "تعديل حساب البنك",
  "Bank Account Number": "رقم الحساب",
  "Bank account number is required!": "رقم الحساب البنكي مطلوب!",
  "Bank account should not contain more then 20 characters!":
    "رقم الحساب البنكي لا يجب أن يحتوي على أكثر من 20 حرفًا!",
  "Bank account should contain at least 3 characters!":
    "رقم الحساب البنكي يجب أن يحتوي على ما لا يقل عن 3 أحرف!",
  "Beneficiary Name": "اسم اسم المستفيد",
  "Beneficiary name is required!": "اسم المستفيد مطلوب!",
  "Name should not contain more then 20 characters!":
    "الاسم لا يجب أن يحتوي على أكثر من 20 حرفًا!",
  "Name should contain at least 2 characters!":
    "الاسم يجب أن يحتوي على ما لا يقل عن 2 أحرف!",
  "C. Chris": "س. كريس",
  "Bank Name": "اسم البنك",
  "Bank name is required!": "اسم البنك مطلوب!",
  "Enter Bank Name": "أدخل اسم البنك",
  "IBAN Number": "رقم الآيبان",
  "IBAN number is required!": "رقم الآيبان مطلوب!",
  "IBAN should not contain more then 34 characters!":
    "الآيبان لا يجب أن يحتوي على أكثر من 34 حرفًا!",
  "IBAN should contain at least 20 characters!":
    "الآيبان يجب أن يحتوي على ما لا يقل عن 20 حرفًا!",
  "Enter IBAN Number": "أدخل رقم الآيبان",
  Deposit: "إيداع",
  "Deposit Amount": "قيمة الايداع",
  "Receipt Number": "رقم الوصل",
  "Upload Receipt": "تحميل صورة الوصل",

  "Order id": "رقم الطلب",
  customer: "العميل",
  "Order Items": "عدد العناصر",
  "Payment Type": "نوع الدفع",
  Date: "التاريخ",
  Amount: "القيمة الاجمالية",
  " View Details": "عرض التفاصيل",
  Invoice: "الفاتورة",
  "total Orders": "مجموع الطلبات",
  "total Sales (COD + Online Sales)": "إجمالي المبيعات (نقدي + الكتروني)",
  Pay: "الدفع",
  "Download Invoice": "تحميل الفاتورة",
  "Order Without Discount": "الطلبات بدون عروض",
  "Order With Discount": "الطلبات مع عروض",
  "Invoice No.": "رقم الفاتورة",
  "Invoice Date": "تاريخ الفاتورة",
  "Total Amount": "القيمة الاجمالية",
  "Pending Invoices": "قائمة جميع الفواتير",

  Download: "تحميل",
  Payment: "الدفعة",
  Status: "الحالة",
  Year: "السنة",
  Month: "الشهر",
  Reset: "اعادة تحديث",
  "Recent Invoice": "الفاتورة الاخيرة",

  "List of All Invoices": "قائمة بجميع الفواتير",
  "Platform Commission": "عمولة المنصة",
  Action: "الإجراء",
  "View Details": "عرض التفاصيل",
  "Order ID": "رقم الطلب",
  "Order Details": "تفاصيل الطلب",
  "Phone Number": "رقم الهاتف",
  "Basket Total": "إجمالي السلة",
  "Delivery Fee": "رسوم التوصيل",
  "10% Discount": "خصم 10%",
  Tip: "بقشيش",
  "Not Assigned": "غير معين",
  Preparing: "قيد التحضير",
  TOTAL: "الإجمالي",
  Delivery: "توصيل",
  Status: "الحالة",
  "Order Type": "نوع الطلب",
  "Payment Mode": "طريقة الدفع",
  "Delivery Distance": "مسافة التوصيل",
  "Vehicle No.": "رقم المركبة",
  "Rest. ID": "رقم المطعم",
  "Collection Date": "تاريخ التحصيل",
  Area: "المنطقة",
  "Total Amount": "القيمة الاجمالية",
  "receipt No.": "رقم الوصل",
  "receipt Image": "صورة الوصل",
  "Payment History": "قائمة الدفع",
  "PAYMENT HISTORY": "قائمة الدفع",

  "Pending Approval Invoices": "فواتير في انتظار الموافقة",
  "PENDING APPROVAL INVOICES": "فواتير في انتظار الموافقة",
  "Grow your business online with Tawasi": "نمو عملك على الإنترنت مع تواصي",
  "Partner with us to reach more customers, earn more money and grow your business online - your success story begins here.":
    "كن شريكًا معنا للوصول إلى المزيد من العملاء، وكسب المزيد من المال، ونمو عملك على الإنترنت - بداية قصة نجاحك تبدأ هنا.",
  "Select country": "اختر البلد",
  "Missing Type Selection": "اختيار النوع مفقود",
  City: "المدينة",
  "Select City": "اختر المدينة",
  Area: "المنطقة",
  "Enter Area": "أدخل المنطقة",
  "Enter Address": "أدخل العنوان",
  "Name should contain more then 600 characters!":
    "يجب أن يحتوي الاسم على أكثر من 600 حرف!",
  "Please Enter Restaurant Name": "يرجى إدخال اسم المطعم",
  "Address Arabic": "العنوان AR",
  "Enter Address": "أدخل العنوان",
  "Address should contain more then 600 characters!":
    "يجب أن يحتوي العنوان على أكثر من 600 حرف!",
  "Restaurant Category": "فئة المتجر",
  "Select Category": "اختر الفئة",
  "Logo is required": "الشعار مطلوب",
  "Upload Logo": "تحميل الشعار",
  "Upload Image": "تحميل الصور",
  "Restaurant’s Name": "اسم المتجر EN",
  "Name should contain more then 250 characters!":
    "يجب أن يحتوي الاسم على أكثر من 250 حرفًا!",
  "Restaurant’s Name Arabic": "اسم المتجر AR",
  "Enter Restaurant Name": "أدخل اسم المطعم",
  "Restaurant Description": "وصف المتجر EN",
  "Enter Restaurant Description": "أدخل وصف المطعم",
  "Description should contain more then 500 characters!":
    "يجب أن يحتوي الوصف على أكثر من 500 حرف!",
  "Working Days": "أيام العمل",
  "Working Time": "أوقات العمل",
  "Open Time": "وقت الفتح",
  "Close Time": "وقت الإغلاق",
  "Contact Person Name": "اسم مسؤول المتجر",
  "Name should contain more then 250 characters!":
    "يجب أن يحتوي الاسم على أكثر من 250 حرفًا!",
  "Email ID": "البريد الالكتروني",
  "Contact Person Phone Number": "رقم موبايل مسؤول المتجر",
  "Enter Phone Number": "أدخل رقم الهاتف",
  "This number have WhatsApp to receive messages?":
    "هذا الرقم فيه واتساب لاستقبال الرسائل؟",
  "Create Password": "إنشاء كلمة مرور",
  "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character":
    "تأكيد كلمة المرور يجب أن تحتوي على 8 أحرف على الأقل، وحرف كبير وحرف صغير ورقم وحرف خاص على الأقل",
  "Please enter your password!": "يرجى إدخال كلمة المرور!",
  "Confirm Password": "تأكيد كلمة المرور",
  "Enter the confirm password!": "أدخل تأكيد كلمة المرور!",
  "Password that you entered doesn't match!":
    "كلمة المرور التي أدخلتها غير مطابقة!",
  "Enter Confirm Password": "أدخل تأكيد كلمة المرور",
  "Delivery Type": "أنواع التوصيل المتاحة في المتجر",
  "Select Delivery Type": "اختر نوع التوصيل",
  "Min. Order Price": "الحد الأدنى لسعر الطلب",
  "Enter Min. Order Price": "أدخل الحد الأدنى لسعر الطلب",
  "Missing Min. Order Price": "الحد الأدنى لسعر الطلب مفقود",
  "Enter Min. Order Price": "أدخل الحد الأدنى لسعر الطلب",
  "Approximate preparation time(HH:MM)": "وقت التحضير التقريبي (س س: د د)",
  "Missing Approximate preparation time": "الوقت التقريبي للتحضير مفقود",
  "Enter preparation time in HH:MM format": "أدخل الوقت التحضيري بتنسيق س:د",
  "Store Tax %": "ضريبة القيمة المضافة %",
  "Please Enter Store Tax Percentage": "يرجى إدخال نسبة ضريبة المتجر",
  "Enter Tax %": "أدخل نسبة الضريبة %",
  "Commission Rate %": "نسبة عمولة المنصة ٪",
  "Cash Commission Rate %": "معدل العمولة النقدية %",
  "Online Commission Rate %": "معدل العمولة عبر الإنترنت٪",
  "Please Enter Commission Rate": "يرجى إدخال نسبة العمولة",
  "Auto Accept Orders": "قبول الطلبات تلقائيًا",
  "Location (Drag Marker for Selecting Location)":
    "موقع المتجر على الخريطة (اسحب العلامة لاختيار الموقع)",
  "Please select the location!": "يرجى اختيار الموقع!",
  "Business Identification Number": "مشتغل مرخص",
  "Enter Business Identification Number": "أدخل رقم التعريف التجاري",
  "Please Enter 16 digit BIN!": "يرجى إدخال 16 رقمًا لـ BIN!",
  "Upload legal document": "صورة رخصة المتجر",
  "Please accept the terms and conditions": "يرجى قبول الشروط والأحكام",
  "I accept the": "أنا أقبل",
  "Contract Terms and Conditions": "شروط وأحكام العقد",
  SUBMIT: "إرسال",
  "Sign In": "تسجيل الدخول",
  "Login to your Account Vendor": "الدخول الى متجرك",
  "See what is going on with your business": "تفضل بمباشرة أعمالك معنا",
  "Email Address": "عنوان البريد الإلكتروني",
  "Please enter a valid email address!": "يرجى إدخال عنوان بريد إلكتروني صالح!",
  "Email address not more then 255 characters!":
    "عنوان البريد الإلكتروني لا يجب أن يتجاوز 255 حرفًا!",
  "Please enter your email!": "يرجى إدخال بريدك الإلكتروني!",
  "Enter Email Address": "أدخل عنوان البريد الإلكتروني",
  Password: "كلمة المرور",
  "Please enter the old password!": "الرجاء إدخال كلمة المرور القديمة!",
  "Please enter the new password!": "الرجاء إدخال كلمة المرور الجديدة!",
  "Old password & new password must be different":
    "يجب أن تكون كلمة المرور القديمة وكلمة المرور الجديدة مختلفتين",
  "Password should contain more then 255 characters!":
    "يجب أن تحتوي كلمة المرور على أكثر من 255 حرفًا!",
  "Please enter your password!": "يرجى إدخال كلمة المرور!",
  "Enter Password": "أدخل كلمة المرور",
  "Remember me": "تذكرني",
  "Forgot Password?": "هل نسيت كلمة المرور؟",
  "SIGN IN": "تسجيل الدخول",
  "Not Registered Yet?": "لم تسجل بعد؟",
  "CREATE AN ACCOUNT": "إنشاء حساب",
  "Send OTP": "إرسال OTP",
  "Forgot Password": "هل نسيت كلمة المرور",
  "Email Address": "عنوان البريد الإلكتروني",
  "Please enter a valid email address!": "يرجى إدخال عنوان بريد إلكتروني صالح!",
  "Email address not more then 255 characters!":
    "عنوان البريد الإلكتروني لا يجب أن يتجاوز 255 حرفًا!",
  "Please enter your email!": "يرجى إدخال بريدك الإلكتروني!",
  "Enter Email Address": "أدخل عنوان البريد الإلكتروني",
  "Verify OTP": "تحقق من OTP",
  Verify: "تحقق",
  OTP: "OTP",
  "Please enter the OTP": "يرجى إدخال الـ OTP",
  "OTP must be 4 digits": "يجب أن يكون الـ OTP مكونًا من 4 أرقام",
  "Enter OTP": "أدخل OTP",
  "Reset Password": "إعادة تعيين كلمة المرور",
  "Update Password": "تحديث كلمة المرور",
  "New Password": "كلمة المرور الجديدة",
  "New password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character":
    "كلمة المرور الجديدة يجب أن تحتوي على 8 أحرف على الأقل، حرف كبير وحرف صغير ورقم وحرف خاص على الأقل",
  "Enter New Password": "أدخل كلمة المرور الجديدة",
  "Confirm New Password": "تأكيد كلمة المرور الجديدة",
  "Please enter the confirm password!": "يرجى إدخال تأكيد كلمة المرور!",
  "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character":
    "تأكيد كلمة المرور يجب أن تحتوي على 8 أحرف على الأقل، حرف كبير وحرف صغير ورقم وحرف خاص على الأقل",
  "Password that you entered doesn't match!":
    "كلمة المرور التي أدخلتها لا تتطابق!",
  "Enter Confirm Password": "أدخل تأكيد كلمة المرور",
  "Orders Out For Delivery": "خارج للتوصيل",
  "NEW ORDERS": "الطلبات الجديدة",
  "PROGRESSING ORDERS": "الطلبات الجارية",
  Cancel: "إغلاق",
  "Enter Category Sort Order": "أدخل ترتيب فرز الفئة",
  "Restaurant Description Arabic": "وصف المتجر AR",
  "Already Registered": "مسجلة بالفعل",
  "Search Location": "ابحث عن الموقع",
  "Enter Contact Person Name": "أدخل اسم جهة الاتصال",
  "Enter Email ID": "أدخل معرف البريد الإلكتروني",
  "Profile image is required": "صورة الملف الشخصي مطلوبة",
  "Upload legal documents": "تحميل الوثائق القانونية",
  Upload: "رفع",
  COD: "نقدا ",
  ONLINE: "على الانترنت",
  "Receive from Driver": "استلام مبلغ من الكابتن",
  "New Order": "طلب جديد",
  Approve: "يوافق",
  Reject: "يرفض",
  "You Will Receive": "ستستلم",
  "Total Platform Commission": "إجمالي عمولة المنصة",
  "Bill Summary": "ملخص الفاتورة",
  "Delivery Agent Details": "تفاصيل وكيل التسليم",
  "Full Payment": "دفع كامل",
  "Partial Payment": "دفع جزء من المال",
  "Choose a Payment": "اختر الدفع",

  "Upload Receipt Image": "تحميل صورة الإيصال",
  "Wire Transfer": "حوالة بنكية",
  Cash: "نقدي",
  "Choose a Payment Method": "اختر طريقة الدفع",
  Payment: "قسط",
  "Start Time": "وقت البدء",
  "End Time": "وقت النهاية",
  cod: "نقدا",
  cod_: "نقدا",
  "Cash on Delivery": "Cash on Delivery",
  pending: "قيد الانتظار",
  "Online Payment": "دفع الكتروني",
  "Please enter your comment": "الرجاء إدخال تعليقك",
  "Driver Tip": "نصيحة للسائق",
  "Free Delivery": "توصيل مجاني",
  "Please wait for delivery agent. They will come to pick the order.":
    "الرجاء انتظار مندوب التوصيل. سوف يأتي لاستلام الطلب.",
  "Prepration time of this order changed successfully":
    "تم تغيير وقت التحضير لهذا الطلب بنجاح",
  mins: "دقائق",
  "Preparation time": "وقت التحضير",
  "Change Preparation Time": "تغيير وقت التحضير",
  Delayed: "متأخر",
  "Slightly Delayed": "متأخر قليلا",
  "Driver Request": "طلب كابتن",
  "Select Area": "حدد المنطقة",
  "Enter Order Total": "أدخل إجمالي الطلب",
  "Is Free Delivery?": "هل التوصيل مجاني؟",
  "Customer Name": "اسم العميل",
  "Customer Phone Number": "رقم هاتف العميل",
  "Floor/Flat": "أرضية/مسطحة",
  "Building Name": "اسم المبنى",
  Landmark: "معلَم مميز",
  "Driver Vehicle Type": "نوع مركبة السائق",
  "Est. Prep. Time": "الوقت المقدر للتحضير",
  "Enter Customer Name": "أدخل اسم العميل",
  "Building Name should contain more then 600 characters!":
    "يجب أن يحتوي اسم المبنى على أكثر من 600 حرف!",
  "Please Enter Building Name": "الرجاء إدخال اسم المبنى",
  "Enter Floor / Flat": "أدخل الطابق / شقة",
  "Floor / Flat should contain more then 600 characters!":
    "يجب أن يحتوي الطابق/الشقة على أكثر من 600 حرف!",
  "Enter Building Name": "أدخل اسم المبنى",
  "Enter Landmark": "معلَم مميزم",
  "Please Enter Landmark": "معلَم مميز",
  "Landmark should contain more then 600 characters!":
    "يجب أن يحتوي المعلم على أكثر من 600 حرف!",
  "Please Enter Phone Number": "يرجى إدخال رقم الهاتف",
  "Please Enter Customer Name": "يرجى إدخال اسم العميل",
  "Missing Order Total": "إجمالي الطلب مفقود",
  "Please Enter Floor/Flat": "يرجى إدخال الطابق/الشقة",
  "Get Current Location": "احصل على الموقع الحالي",
  "Receive From Driver": "إستلام من الكابتن",
  "Receive from Customer": "استلام من العميل",
  "Vendor to be charged the delivery fee.": "يتحمل البائع رسوم التوصيل.",
  "Wallet Transactions": "معاملات المحفظة",
  "List of All Transactions": "قائمة بجميع المعاملات",
  "Transaction Date": "تاريخ العملية",
  "Withdraw money": "سحب المال",
  Withdraw: "ينسحب",
  "Withdraw Amount": "سحب المبلغ",
  "Withdraw Amount is required!": "مطلوب سحب المبلغ!",
  Reason: "سبب",
  "Reason is required!": "السبب مطلوب!",
  Credit: "ائتمان",
  Debit: "مدين",
  "View All":"عرض الكل",
  "Add Money": "إضافة المال",
  "Total Platform Commision": "إجمالي عمولة المنصة",
  "Search by Item Name": "البحث حسب اسم العنصر",
  "Search Customer": " البحث عن العميل",
  "Enter Customer Name and Mobile Number": "إبحث عن العميل بالاسم أو رقم الموبايل",
  "Number must start with number 05.": "يجب أن يبدأ الرقم بالرقم 05.",
  "Number must be 10 digits.": "يجب أن يكون الرقم 10 أرقام.",
  "Start with 5": "إبدء برقم 5",
  "Financial Transactions": "الامور المالية",
  "Withdraw Requests":"",
  "PENDING REQUESTS":"الطلبات المعلقة",
  "Pending Approval":"موافقة قيد الانتظار",
  "Deposit Requests":"طلبات الإيداع",
  "Request Date":"تاريخ الطلب",
  "Approved":"موافقة",
  "Pending":"قيد الانتظار",
  "Request Status":"حالة الطلب",
  "Wallet balance":"رصيد المحفظة",
  "Order":"طلب",
  "Discount":"الخصم ",
  "Cash Collection":"التحصيل النقدي",
  "View Invoice":"عرض الفاتورة",
  "Please add funds to your wallet as your balance is currently low":"يرجى إضافة أموال إلى محفظتك لأن رصيدك منخفض حاليًا",
  "Top Up":"اشحن رصيدك",
  "Picked up by driver":"التقطت من قبل السائق",
  "Invoices":"الفواتير",
  "Opps.. Please check your WhatsApp number. In Palestine, please check your country code registered on WhatsApp 970 or 972 and try again":"عفوا! يرجى التحقق من رقم الواتساب لديك، في فلسطين يرجى التحقق من تسجيل رقمك الواتساب باستخدام 970 أم 972 ومن ثم عاود التسجيل.",
  "Company Order History":"تاريخ طلب الشركة"
};

export default ar;
